import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@shared/models/dialog-data.model';
import { MenuItemModel } from '@shared/models/menu-item.model';
import { CartService } from '@shared/services/cart.service';

@Component({
  selector: 'app-add-note-dialog',
  templateUrl: 'add-note-dialog.component.html',
  styleUrls: ['add-note-dialog.component.scss'],
})
export class AddNoteDialogComponent {
  notes: string = '';
  item:MenuItemModel;
  constructor(
    private cartSerivce: CartService,
    public dialogRef: MatDialogRef<AddNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.item=data.item;
  }

  onClick(res: boolean): void {
    this.dialogRef.close(res);
  }

  updateNotes(event: Event) {
    const target = event.target as HTMLInputElement;
    this.notes = target.value;
  }

  addItem() {
    this.cartSerivce.addToCart(this.item, this.notes);
    this.dialogRef.close();
  }
}
