import {Component} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import {AuthService} from "@services/auth.service";
import {TokenModel} from "@models/token.model";
import {BehaviorSubject} from "rxjs";
import {StorageKeysEnum} from "@shared/enum/storage-keys.enum";
import {UserModel} from "@models/user.model";
import {Router} from "@angular/router";
import {AppDetailsDialogComponent} from "@shared/components/app-details-dialog/app-details-dialog.component";
import {NbWindowService} from "@nebular/theme";

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'})),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'})),
      ]),
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'})),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'})),
      ]),
    ]),
  ],
})
export class NavRightComponent {
  visibleUserList: boolean;
  chatMessage: boolean;
  friendId: boolean;

  user: UserModel=null;

  constructor(config: NgbDropdownConfig,
              private authService: AuthService,
              private windowService: NbWindowService,
              private router: Router
  ) {
    const tokenModel: TokenModel = new BehaviorSubject<TokenModel>(JSON.parse(
      localStorage.getItem(StorageKeysEnum.USER)!)).value as TokenModel;
    if (tokenModel != null && tokenModel.profile != null) {
      this.user = tokenModel.profile;
    }

    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
  }

  logout() {
    this.authService.logout();
  }
  userProfile() {
    this.router.navigate(['/profile']);
  }
  appVersion()
  {
    this.windowService.open(
      AppDetailsDialogComponent,
      {
        title: 'maedah Admin',
        context: {id: null},
        windowClass: 'custom-window',
        closeOnBackdropClick: false,
      },
    );
  }
}
