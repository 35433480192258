export enum NotificationTypeEnum {
  REQUEST_DRAFT = 1 ,
  REQUEST_COMPLETED = 2 ,
  REQUEST_PAID = 3 ,
  REQUEST_PAYMENT_FAILED = 4 ,
  NEW_ORDER = 5 ,
  REQUEST_APPROVED = 6 ,
  REQUEST_REJECTED = 7 ,
  REQUEST_CANCELLED = 8 ,
  REQUEST_RECEIVED = 9 ,
  GENERAL = 10 ,
  UNLOCK_RESTAURANT_TABLE = 11 ,
  CANCEL_ORDER = 12,
  NOTIFY_WAITER = 13,
  NOTIFY_WAITER_TO_UPDATE_ORDER = 14
}
