import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { distinctUntilChanged, of, Subject, switchMap } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { TableService } from '@shared/services/table/table.service';
import { NewMenuCategoriesService } from '@shared/services/new-menuCategories/new-menu-categories.service';

@Component({
  selector: 'app-select',
  templateUrl: 'select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectComponent }],
})
export class SelectComponent implements OnInit, ControlValueAccessor, MatFormFieldControl<any>, OnDestroy {
  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'app-select';
  id = `app-select-${SelectComponent.nextId++}`;

  searching = false;
  input$ = new Subject<string>();
  selectedItem: any;

  @Input() type = '';
  @Input() items: any[] = [];
  @Input() placeholder = '';
  @Input() label = '';
  @Input() multiple = false;
  @Input() display = 'title';
  @Input() bindValue!: string;
  @Input() dropdownPosition: any;
  @Input() addTag = false;
  @Input() setValue: any;
  @Input() menuId:string = '';
  @Output() selectEvent = new EventEmitter<any>();
  @Output() removeEvent = new EventEmitter<any>();
  @Output() tagEvent = new EventEmitter<boolean>();
 
  constructor(
    private tablesService: TableService,
    private categoriesService:NewMenuCategoriesService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get empty() {
    return this.selectedItem === null;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get value(): any | null {
    return this.selectedItem;
  }

  set value(model: any | null) {
    this.selectedItem = model;
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.selectedItem === null && this.touched;
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onTouched = () => {};

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.xc-select-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onContainerClick() {}

  writeValue(model: any | null): void {
    this.value = model;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
    this.selectEvent.emit(this.value);
  }

  ngOnInit() {
    if (this.multiple) {
      this.selectedItem = [];
    } else {
      this.selectedItem = null;
    }

    if (this.setValue) {
      this.selectedItem = this.setValue;
    }

    switch (this.type) {
      case 'tables':
        this.loadTabels();
        break;
    }

    switch (this.type) {
      case 'tables':
        this.filterTabels();
        break;
    }

    switch (this.type) {
      case 'categories':
        this.loadCategories();
        break;
    }

    switch (this.type) {
      case 'categories':
        this.filterCategories();
        break;
    }
  }

  compareCardObjects(object1: any, object2: any) {
    if (object1.value && object2.value) {
      return object1 && object2 && object1.value === object2.value;
    }
    return object1 && object2 && object1.id === object2.id;
  }

  addTagFn($event: any) {
    return $event;
  }

  handleRemove($event: any) {
    this.removeEvent.emit($event);
  }

  loadTabels() {
    this.tablesService.getAll(1, 100, null, null).subscribe({
      next: value => {
        this.items = value.data;
      },
      error: () => {},
    });
  }

  filterTabels() {
    this.input$
      .pipe(
        distinctUntilChanged(),
        tap(value => {
          if (value === null) {
            return;
          }
          this.searching = true;
        }),
        debounceTime(300),
        switchMap(term =>
          this.tablesService.getAll(1, 100, null, term).pipe(
            catchError(() => of([])),
            tap(() => {
              this.searching = false;
            })
          )
        )
      )
      .subscribe({
        next: (value: any) => {
          this.items = value.data;
        },
      });
  }

  loadCategories() {
    this.categoriesService.getAll(this.menuId, 1, 100, null, null).subscribe({
      next: value => {
        this.items = value.data;
      },
      error: () => {},
    });
  }

  filterCategories() {
    this.input$
      .pipe(
        distinctUntilChanged(),
        tap(value => {
          if (value === null) {
            return;
          }
          this.searching = true;
        }),
        debounceTime(300),
        switchMap(term =>
          this.categoriesService.getAll(this.menuId, 1, 100, null, term).pipe(
            catchError(() => of([])),
            tap(() => {
              this.searching = false;
            })
          )
        )
      )
      .subscribe({
        next: (value: any) => {
          this.items = value.data;
        },
      });
  }
}
