import {SelectComponent} from "./components/select/select.component";
import {PaginatorComponent} from "./components/paginator/paginator.component";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {MatCardModule} from "@angular/material/card";
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {HttpClientModule} from '@angular/common/http';
import {NbCardModule, NbIconModule} from "@nebular/theme";
import {SearchComponent} from "./components/search/search.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypesPipe} from "@shared/pipes/types.pipe";
import {DateAndTimePipe} from "@shared/pipes/date-and-time-pipe";
import {DateFormatPipe} from "@shared/pipes/date-pipe";
import {CommonModule, NgClass, NgIf} from "@angular/common";
import {ReadMoreComponent} from "@shared/components/read-more/read-more.component";
import {StopPropagationDirective} from "@shared/directives/stop-propagation-directive";
import {TimeMaskDirective} from "@shared/directives/time-mask.directive";
import {SafePipe} from "@shared/pipes/safe-pipe";
import { WebcamSnapshotComponent } from './components/webcam-snapshot/webcam-snapshot.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { GenerateQrDialogComponent } from "./components/generate-qr-dialog/generate-qr-dialog.component";
import { NgxMatColorPickerModule, MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { PriceFormatterDividePipe } from "@shared/pipes/priceFormatterDivide.pipe";
import { PriceFormatterMultiplyPipe } from "@shared/pipes/priceFormatterMultiply.pipe";
import {NotificationsComponent} from "./components/notifications/notifications.component";
import {MatDividerModule} from "@angular/material/divider";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AppDetailsDialogComponent} from "@shared/components/app-details-dialog/app-details-dialog.component";
import { MatTabsModule } from "@angular/material/tabs";
import { CardComponent } from "./components/card/card.component";
import { PanelCardComponent } from "./components/panel-card/panel-card.component";
import { SwiperComponent } from "./components/swiper/swiper.component";
import { register } from 'swiper/element/bundle';
import { ItemCardComponent } from "./components/item-card/item-card.component";
import { ImageWithLazyimageComponent } from "./components/image-with-lazyimage/image-with-lazyimage.component";
import { AddNoteDialogComponent } from "./components/add-note-dialog/add-note-dialog.component";
import { AddOrderButtonComponent } from "./components/add_order_button/add-order-button.component";
import { ScrollObserverDirective } from "./directives/scroll-observer.directive";
import { HeaderComponent } from "./components/header/header.component";
import { SortButtonComponent } from "./components/sort-button/sort-button.component";
import { ButtonContainerComponent } from "./components/button-container/button-container.component";
import { IsEmptyMessageComponent } from "./components/is-empty-message/is-empty-message.component";
import { AddButtonComponent } from "./components/add-button/add-button.component";
import { ImageIconContainerComponent } from "./components/image-icon-container/image-icon-container.component";
import { StarRatingComponent } from "./components/star-rating/star-rating.component";
import { NotFoundMessageComponent } from "./components/not-found-message/not-found-message.component";
// register Swiper custom elements
register();

const COMPONENTS = [
  PaginatorComponent,
  ReadMoreComponent,
  ConfirmationDialogComponent,
  GenerateQrDialogComponent,
  SearchComponent,
  SelectComponent,
  NotificationsComponent,
  AppDetailsDialogComponent,
  CardComponent,
  SwiperComponent,
  PanelCardComponent,
  ItemCardComponent,
  ImageWithLazyimageComponent,
  AddNoteDialogComponent,
  AddOrderButtonComponent,
  ButtonContainerComponent,
  SortButtonComponent,
  IsEmptyMessageComponent,
  HeaderComponent,
  AddButtonComponent,
  ImageIconContainerComponent,
  StarRatingComponent,
  NotFoundMessageComponent
];
const PIPES = [
  TypesPipe,
  SafePipe,
  DateAndTimePipe,
  DateFormatPipe,
  PriceFormatterDividePipe,
  PriceFormatterMultiplyPipe,
];
const MAT_MODULES = [
  MatPaginatorModule,
  MatDialogModule,
  MatDividerModule,
  MatTabsModule,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    StopPropagationDirective,
    TimeMaskDirective,
    ScrollObserverDirective,
    WebcamSnapshotComponent,

  ],
  providers: [
    PriceFormatterDividePipe,
    PriceFormatterMultiplyPipe,
    {provide: MAT_DIALOG_DATA, useValue: {}},
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
  ],
    imports: [
        ...MAT_MODULES,
        MatCardModule,
        HttpClientModule,
        NbCardModule,
        NgIf,
        NgClass,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatIconModule,
        MatSelectModule,
        NgxMatColorPickerModule,
        NgSelectModule,
        NgbModule,
        MatTooltipModule,
        MatTabsModule,
        CommonModule,
        MatSnackBarModule,
        NbIconModule,
    ],
  exports: [
    ...COMPONENTS,
    ...MAT_MODULES,
    ...PIPES,
    StopPropagationDirective,
    TimeMaskDirective,
    ScrollObserverDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})


export class CoreModule {
}
