import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['search.component.scss'],
})
export class SearchComponent {
  @Output() searchEvent = new EventEmitter<string>();
  @Input() placeholder = '';
  @Input() filterKeyword: string | null = '';
  @Input() always = false;

  private searchSubject = new Subject<string>();

  constructor() {
    // Debounce the search input
    this.searchSubject.pipe(
      debounceTime(1000) // Wait for 1s pause in events
    ).subscribe(keyword => {
      this.filterKeyword = keyword;
      this.emitKeyword();
    });
  }

  // Triggered on every input change
  onSearchChange(keyword: string) {
    this.searchSubject.next(keyword);
  }

  emitKeyword() {
    this.searchEvent.emit(this.filterKeyword!);
  }
}
