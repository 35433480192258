import { Component, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DattaConfig } from '../../../app-config';
import { MobileService } from '@shared/services/mobile.service';
import { MobileMenuItemInputs } from './mobile-menu/mobile-menu-item/mobile-menu-item.component';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';
import { AppDetailsDialogComponent } from '@shared/components/app-details-dialog/app-details-dialog.component';
import { NbWindowService } from '@nebular/theme';
import { RestaurantService } from '@shared/services/restaurant/restaurant.service';
import { CompanyService } from '@shared/services/company.service';
import { StaffModel } from '@shared/models/staff.model';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { BranchModel } from '@shared/models/branch.model';
import { StorageKeysEnum } from '@shared/enum/storage-keys.enum';
import { LocalStorageRefService } from '@shared/services/local-storage-ref.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit{
  navCollapsed: any;
  navCollapsedMob: boolean;
  windowWidth: number;

  mobileMenuItems: MobileMenuItemInputs[] = [
    {
      label: 'Dashboard',
      icon: 'assets/new-icons/menu-menus.svg',
      route: '/dashboard',
    },
    {
      label: 'Control Panel',
      icon: 'assets/new-icons/menu-controlpanel.svg',
      route: '/panel',
    },
    {
      label: 'Orders',
      icon: 'assets/new-icons/menu-orders.svg',
      route: '/orders',
    },
    {
      label: 'Tables',
      icon: 'assets/new-icons/menu-tables.svg',
      route: '/tables',
    },
    {
      label: 'Restaurants',
      icon: 'assets/new-icons/menu-rest.svg',
      route: '/restaurant',
    },
    {
      label: 'Profile',
      icon: 'assets/new-icons/menu-profile.svg',
      route: '/profile',
    },
    {
      label: 'Menus',
      icon: 'assets/new-icons/menu-menus.svg',
      route: '/menus',
    },
    {
      label: 'Staff',
      icon: 'assets/new-icons/menu-staff.svg',
      route: '/staff',
    },
    {
      label: 'Review',
      icon: 'assets/new-icons/menu-staff.svg',
      route: '/review',
    },
    {
      label: 'Terms And Conditions',
      icon: 'assets/new-icons/menu-terms.svg',
      route: '/terms-and-condition',
    },
    {
      label: 'Logout',
      icon: 'assets/new-icons/menu-logout.svg',
      route: '',
      clickCallback: () => {
        this.authService.logout();
      },
    },
  ];

  logout() {
    this.authService.logout();
  }
  userProfile() {
    this.router.navigate(['/profile']);
  }
  appVersion() {
    this.windowService.open(AppDetailsDialogComponent, {
      title: 'maedah Admin',
      context: { id: null },
      windowClass: 'custom-window',
      closeOnBackdropClick: false,
    });
  }

  get pageName() {
    const url = this.router.url;
    const removedSlash = url.substring(1);
    const firstSegment = removedSlash.split('/')[0];
    const formattedTitle = firstSegment
      .split('-')
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
    return formattedTitle;
  }

  userType: string = '';
  staff: StaffModel[] = [];
  branches: BranchModel[] = [];
  selectedCompany: any;
  selectedRestaurant: any;
  selectedBranch: any;
  constructor(
    private zone: NgZone,
    private location: Location,
    public mobileService: MobileService,
    private authService: AuthService,
    private router: Router,
    private windowService: NbWindowService,
    public restaurantService: RestaurantService,
    private localStorageRefService: LocalStorageRefService,
    public companyService: CompanyService
  ) {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }

    if (
      current_url === this.location['_baseHref'] + '/layout/collapse-menu' ||
      current_url === this.location['_baseHref'] + '/layout/box'
    ) {
      DattaConfig.isCollapseMenu = true;
    }

    this.windowWidth = window.innerWidth;
    this.navCollapsed = this.windowWidth >= 992 ? DattaConfig.isCollapseMenu : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {
    this.userType = localStorage.getItem('userType');
  
    if (this.userType === 'staff') {
      this.staff = this.authService.getStaff();
    }
  
    // Retrieve restaurant and branches from local storage
    const restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
    this.branches = restaurant?.branches || [];
  
    // Initialize selected restaurant by matching the ID with restaurantService.restaurants
    if (this.restaurantService.restaurants && this.restaurantService.restaurants.length > 0) {
      this.selectedRestaurant = this.restaurantService.restaurants.find(
        (r) => r.id === restaurant.id
      );
    }
  
    // Initialize selected branch by finding the branch in localStorage
    const savedBranch = JSON.parse(localStorage.getItem('branch')); // Assuming branch is saved in local storage
    if (savedBranch) {
      this.selectedBranch = this.branches.find(branch => branch.id === savedBranch.id);
    } else if (this.branches && this.branches.length > 0) {
      this.selectedBranch = this.branches[0]; // Default to first branch
    }
  }
  

  setCompany(item: any) {
    this.authService.saveCompany(item, 'admin');
    window.location.reload();
  }

  setRestaurant(item: any) {
    this.localStorageRefService.removeData(StorageKeysEnum.BRANCH);
    console.log(item)
    if(item.branches && item.branches[0]) {
      this.authService.saveBranch(item.branches[0])
    }
    this.authService.saveRestaurant(item);
    window.location.reload();
  }

  setBranch(branch: any) {
    console.log('Selected branch:', branch); // This should log the correct branch object
    if (branch && branch.id) {
      this.authService.saveBranch(branch); // Ensure you're saving the full branch object, not just the ID
      window.location.reload();
    }
  }

  setStaff(staff) {
    this.authService.saveStaff(staff);
  }

  navMobClick() {
    if (
      this.navCollapsedMob &&
      !document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')
    ) {
      this.navCollapsedMob = !this.navCollapsedMob;
      setTimeout(() => {
        this.navCollapsedMob = !this.navCollapsedMob;
      }, 100);
    } else {
      this.navCollapsedMob = !this.navCollapsedMob;
    }
  }
}
