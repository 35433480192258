import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PermissionService } from "@services/permission.service";
import { FCMService } from "@services/fcm.service";
import { SwUpdate } from "@angular/service-worker";
import { AppUpdateService } from "@services/app-update.service";
import { AppDetailsDialogComponent } from "@shared/components/app-details-dialog/app-details-dialog.component";
import { NbWindowService } from "@nebular/theme";
import { environment } from "@env";
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Maedah Admin';

  constructor(private router: Router,
              private fcmService: FCMService,
              private permissionsService: PermissionService,
              private windowService: NbWindowService,
              private swUpdate: SwUpdate,
              private appUpdateService: AppUpdateService) {
    this.permissionsService.loadPermissions();
  }

  ngOnInit() {
    this.fcmService.requestPermission();
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    const userprofile: any = JSON.parse(localStorage.getItem('user'));
    if (!userprofile) {
      this.router.navigate(['/auth/login']);
    }

    if (this.swUpdate.isEnabled) {
      this.appUpdateService.updateChecker().subscribe(() => {
        this.appUpdateService.availableStatus.next(true);
        this.windowService.open(
          AppDetailsDialogComponent,
          {
            title: 'Maedah Admin',
            context: { id: null },
            windowClass: 'custom-window',
            closeOnBackdropClick: false,
          },
        );
      });
    }

    const style = 'color:#0e355e; padding: 10px 20px; font-size: 20px; font-family: Courier New; text-shadow: 2px 2px 5px #4294ff;';
    const rainbowStyle = 'font-weight: bold; font-size: 50px; color: orange; font-family: Courier New; text-shadow: 2px 2px 5px #4294ff;';

    console.log('%cMaedah', rainbowStyle);
    console.log(`%cMaedah \nUpdated ${environment.updated_at} \nVersion ${environment.version}+${environment.build}`, style);
  }

  fromNow = (a: any) => {
    if (moment().diff(a, 'hours') > 24) {
      return `at ${a}`;
    }
    return moment(a).fromNow();
  };
}
