import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '@theme/layout/admin/admin.component';
import { GuestComponent } from '@theme/layout/guest/guest.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'restaurant',
        loadChildren: () =>
          import('./demo/pages/new-restaurant/new-restaurant.module').then(m => m.NewRestaurantModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./demo/pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'staff',
        loadChildren: () => import('./demo/pages/new-staff/new-staff.module').then(m => m.NewStaffModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./demo/pages/new-orders/new-order.module').then(m => m.NewOrderModule),
      },
      {
        path: 'panel',
        loadChildren: () => import('./demo/pages/panel/panel.module').then(m => m.PanelModule),
      },
      {
        path: 'menus',
        loadChildren: () => import('./demo/pages/new-menus/new-menus.module').then(m => m.NewMenusModule),
      },
      {
        path: 'tables',
        loadChildren: () => import('./demo/pages/new-table/new-table.module').then(m => m.NewTableModule),
      },
      {
        path: 'terms-and-condition',
        loadChildren: () => import('./demo/pages/terms-and-condition/terms-and-condition.module').then(m => m.TermsAndConditionModule),
      },
      {
        path: 'review',
        loadChildren: () => import('./demo/pages/review/review.module').then(m =>m.ReviewModule),
      },
    ],
  },
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
