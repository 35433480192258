import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appScrollObserver]',
})
export class ScrollObserverDirective implements OnInit, OnDestroy {
  @Output() elementIsInView: EventEmitter<void> = new EventEmitter<void>();
  private observer: IntersectionObserver;

  constructor(private targetElement: ElementRef<HTMLElement>) {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.elementIsInView.emit();
        }
      });
    });
  }
  ngOnInit(): void {
    this.observer.observe(this.targetElement.nativeElement as Element);
  }
  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
