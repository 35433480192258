import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dividePrice',
  pure: false,
})
export class PriceFormatterDividePipe implements PipeTransform {
  transform(value: number): number {
    const result = value / 100;
    return parseFloat(result.toFixed(2));
  }
}
