import {Component, EventEmitter, Output} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {Router} from "@angular/router";
import {NbWindowService} from "@nebular/theme";
import {AppDetailsDialogComponent} from "@shared/components/app-details-dialog/app-details-dialog.component";

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
    @Output() NavCollapsedMob = new EventEmitter();
    navCollapsedMob;
    headerStyle: string;
    menuClass: boolean;
    collapseStyle: string;

    constructor(private authService: AuthService ,  private windowService: NbWindowService,
                  private router: Router) {
        this.navCollapsedMob = false;
        this.headerStyle = '';
        this.menuClass = false;
        this.collapseStyle = 'none';
    }

    logout() {
        this.authService.logout();

    }
  userProfile() {
    this.router.navigate(['/profile']);
  }
  appVersion()
  {
    this.windowService.open(
      AppDetailsDialogComponent,
      {
        title: 'maedah Admin',
        context: {id: null},
        windowClass: 'custom-window',
        closeOnBackdropClick: false,
      },
    );
  }
}
