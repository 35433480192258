import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-card',
  templateUrl: './panel-card.component.html',
  styleUrls: ['./panel-card.component.css'],
})
export class PanelCardComponent {
  @Input() status: string = '';
  @Input() tableNumber: string = '';
  @Input() time: string = '';
  @Input() currency: string = '';
  @Input() totalAmount: number = 0;
  @Input() paidAmount: number = 0;
}
