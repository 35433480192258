import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@services/cart.service';
import { MenuItemModel } from '@shared/models/menu-item.model';
import { RouteParamsInterface } from '@shared/models/routeParams.interface';
import { OrderService } from '@shared/services/order/order.service';

@Component({
  selector: 'app-add-order-button',
  templateUrl: './add-order-button.component.html',
  styleUrls: ['./add-order-button.component.scss'],
})
export class AddOrderButtonComponent implements OnInit {
  @Input() isItemsToOrder:boolean = false;
  quantity = 0;
  isBrowser: boolean = false;
  cartProducts: MenuItemModel[] = [];
  tableId: string = '';
  orderId: string = '';
  isLoading: boolean = false;
  constructor(
    public cartService: CartService,
    private orderService: OrderService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    if(this.isItemsToOrder) {
      const params = this.route.snapshot.params as RouteParamsInterface;
      this.orderId = params.order_id;
    } else {
      const params = this.route.snapshot.params as RouteParamsInterface;
      this.tableId = params.table_id;
      this.cartService.updateCartQuantity();
      this.cartService.updateTotalPrice();
    }
  }

  addOrder() {
    this.isLoading=true;
    const orderItems: { id?: string; notes?: string }[] = [];
    if ('cart' in localStorage) {
      const cartProduct: string | null = localStorage.getItem('cart');
      if (cartProduct) {
        this.cartProducts = JSON.parse(cartProduct) as MenuItemModel[];
      }
    }
    for (const item of this.cartProducts) {
      orderItems.push({ id: item.id, notes: item.notes });
    }
    const order = {
      restaurant_table_id: this.tableId,
      order_items: orderItems,
    };
    if(this.isItemsToOrder) {
      this.orderService.addItemToSpecificOrder(this.orderId, order).subscribe({
        next: res => {
          const orderId= res.data.id;
          localStorage.removeItem('cart');
          this.router.navigate([`/orders/${orderId}`])
          this.isLoading= false;
        },
        error: err => {
          this.isLoading= false;
          this.snackBar.open(err.message, '', {
            duration: 20000,
            panelClass: ['snackbar'],
          });
        },
      });
    } else {
      this.orderService.addOrder(order).subscribe({
        next: res => {
          const orderId= res.data.id;
          localStorage.removeItem('cart');
          this.router.navigate([`/orders/${orderId}`])
          this.isLoading= false;
        },
        error: err => {
          this.isLoading= false;
          this.snackBar.open(err.message, '', {
            duration: 20000,
            panelClass: ['snackbar'],
          });
        },
      });
    }

  }
}
