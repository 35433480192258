<div
  class="p-0 m-3 mx-auto tw-size-40 tw-rounded-xl border-1 bg-white"
  [ngClass]="{
    'secondary-background-color': status === 'PENDING',
    'main-background-color':
      status === 'PREPARING' || status === 'ACCEPTED' || status === 'READY' || status === 'CANCELLED',
    'strong-orange-background-color':
      status === 'PARTIALLY_PAID' || status === 'OCCUPIED' || status === 'PENDING_USER_EDIT',
    'mauve-background-color': status === 'RESERVED',
    'green-background-color': status === 'FULLY_PAID',
    'strong-orange-border-color': status === 'LOCK'
  }">
  <div
    class="d-flex justify-content-center justify-align-center tw-h-8 tw-w-8 tw-rounded-tl-xl tw-rounded-br-lg glass-background"
    [ngClass]="{ 'gray-background-color': status === 'AVAILABLE', 'bg-lock': status === 'LOCK' }">
    <p class="p-0 m-auto tw-text-base tw-font-medium text-white">{{ tableNumber }}</p>
  </div>
  <div class="w-100 h-50 p-2 tw-flex-col m-auto">
    <img *ngIf="status === 'PENDING'" class="tw-size-10 m-auto" src="assets/images/tableimage.png" alt="" />
    <img *ngIf="status === 'AVAILABLE'" class="tw-size-10 m-auto" src="assets/images/emptyTable.png" alt="" />
    <img *ngIf="status === 'PREPARING'" class="tw-size-10 m-auto" src="assets/images/preparingOrder.png" alt="" />
    <img
      *ngIf="status === 'ACCEPTED' || status === 'READY' || status === 'OCCUPIED'"
      class="tw-size-10 m-auto"
      src="assets/images/accptedOrder.png"
      alt="" />
    <img *ngIf="status === 'RESERVED'" class="tw-size-10 m-auto" src="assets/images/ReservedTable.png" alt="" />
    <img *ngIf="status === 'LOCK'" class="tw-size-10 m-auto" src="assets/images/lockTable.svg" alt="" />
    <img
      *ngIf="status === 'FULLY_PAID' || status === 'PARTIALLY_PAID'"
      class="tw-size-10 m-auto"
      src="assets/images/orderPaid.png"
      alt="" />
    <p
      class="text-white tw-text-xs text-center mt-1"
      [ngClass]="{ 'gray-color': status === 'AVAILABLE', 'strong-orange-color': status === 'LOCK' }">
      {{ time }}
    </p>
    <p
      class="tw-text-sm text-center text-white"
      [ngClass]="{ 'gray-color': status === 'AVAILABLE', 'strong-orange-color': status === 'LOCK' }">
      <span>{{ paidAmount | dividePrice }} {{ currency }}</span>
      / {{ totalAmount | dividePrice }}
      {{ currency }}
    </p>
    <div class="mt-2">
      <div
        class="rounded-5 text-center py-1 mt-1 glass-background"
        [ngClass]="{ 'gray-background-color': status === 'AVAILABLE', 'bg-lock': status === 'LOCK' }">
        <p class="text-white tw-text-xs">{{ status | formatType }}</p>
      </div>
    </div>
  </div>
</div>
