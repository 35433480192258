import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-image-with-lazyimage',
  templateUrl: './image-with-lazyimage.component.html',
  styleUrls: ['./image-with-lazyimage.component.scss'],
})
export class ImageWithLazyimageComponent {
  @Input() image: string = '';
  isImgLoaded: boolean = false;
}
