import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-switcher-language',
  templateUrl: './switcher-language.component.html',
  styleUrls: ['./switcher-language.component.scss'],
})
export class SwitcherLanguageComponent {
  selectedLanguage: string = '';
  protected readonly locales = ['en', 'ar'];
  Language:string='Language';
  constructor(public translate: TranslateService) {
    const savedLanguage =this.translate.currentLang;
    if (savedLanguage) {
      this.translate.use(savedLanguage);
      this.selectedLanguage=savedLanguage;
      if(savedLanguage==='ar') {
        this.Language='English'
      } else {
        this.Language='العربية'
      }
    }
  }

  toggleLanguage(): void {
    const selectedLanguage = (this.selectedLanguage === 'en') ? 'ar' : 'en';
    this.changeLanguage(selectedLanguage)
  }

  changeLanguage(language: string) {
    const localStorage = document.defaultView?.localStorage;
    this.translate.use(language);
    if (localStorage) {
      localStorage.setItem('language', language);
    }
    const Language = language;
    this.selectedLanguage = Language;
    this.selectedLanguage = this.selectedLanguage[0].toUpperCase() + this.selectedLanguage.slice(1);
    window.location.reload();
  }
}
