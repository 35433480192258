import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import { MenuCategoryModel } from '@shared/models/menu-category.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import Swiper from 'swiper';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SwiperComponent implements AfterViewInit{
  @Input() menuCategories: MenuCategoryModel[] = [];
  menuCategoryUpdate!: Subscription;
  swiper?: Swiper;
  @Input() categoryTitle: string = '';
  sliderActive: number = 0;
  @ViewChild('swiperRef') swiperRef: ElementRef<HTMLElement> | undefined;
  @ViewChildren('categoryRefs') categoryRefs: QueryList<ElementRef> | undefined;

  constructor(
    private spinnerService: NgxSpinnerService,
  ) {
  }

  onSlideClick(categoryIndex: number, title: string) {
    this.categoryTitle = title;
    const hasCategories = this.menuCategories && this.menuCategories.length > categoryIndex;
    if (hasCategories) {
      const firstMenuItemElement = this.categoryRefs?.find(
        categoryRef => (categoryRef.nativeElement as HTMLElement).id === title
      );
      if (firstMenuItemElement) {
        (firstMenuItemElement.nativeElement as HTMLElement).scrollIntoView({ behavior: 'smooth' });
      }

      if (this.swiper) {
        this.swiper.slideTo(categoryIndex);
      }
    }
    this.sliderActive = categoryIndex;
  }

  onElementInView(index: number) {
    if (this.swiper) {
      this.swiper.slideTo(index);
      this.sliderActive = index;
    }
  }

  ngAfterViewInit(): void {
    this.initiateSwiper();
  }

  private initiateSwiper() {
    this.swiper = (this.swiperRef?.nativeElement as any)?.swiper as Swiper;
    const swiperEl = this.swiperRef?.nativeElement as HTMLElement;
    const swiperParams = {
      slidesPerView: 1,
      clickable: true,
      spaceBetween: 10,
      breakpoints: {
        300: {
          slidesPerView: 2.6,
        },
        500: {
          slidesPerView: 3.8,
        },
        600: {
          slidesPerView: 4,
        },
        1100: {
          slidesPerView: 5,
        },
      },
    };
    Object.assign(swiperEl, swiperParams);
  }

}
