<div class="">
  <a [routerLink]="['/dashboard/']" class="b-brand">
    <img style="height: 60px; object-fit: cover;" src="assets/images/maedah-logo.jpg">
  </a>
  <a
    href="javascript:"
    class="mobile-menu"
    [ngClass]="{ on: navCollapsed }"
    id="mobile-collapse"
    (click)="navCollapse()"
  ><span></span
  ></a>
</div>
