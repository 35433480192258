import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PermissionsEnum } from '@shared/enum/permissions.enum';
import { RevirGoogleTypeEnum } from '@shared/enum/reviewGoogleType.enum';
import { DialogData } from '@shared/models/dialog-data.model';
import { HttpErrorModel } from '@shared/models/http-error.model';
import { SettingsModel } from '@shared/models/settings.model';
import { LanguageService } from '@shared/services/language.service';
import { SettingService } from '@shared/services/setting/setting.service';
import { ToastService } from '@shared/services/toast.service';
import { MethodsUtils } from '@shared/utils/methods.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs';

@Component({
  selector: 'app-review-settings-dialog',
  templateUrl: 'review-settings-dialog.component.html',
  styleUrls: ['review-settings-dialog.component.scss'],
})
export class ReviewSettingsDialogComponent implements OnInit{
  reviewGoogleTypeEnum: any;
  permissionUpdate: string;
  permissionStore: string;
  submitted = false;
  reviewForm: UntypedFormGroup;
  settings: SettingsModel | null = null;
  reviewSettings: any | null = null;
  httpError: HttpErrorModel | null = null;
  formData = new FormData();
  isAddreviewSettings: boolean = false;
  isEditreviewSettings: boolean = false;
  starsThreshold = [1, 2, 3, 4, 5];
  showStarsThreshold = false;
  constructor(
    private methodUtils: MethodsUtils,
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private toastService: ToastService,
    private settingsService: SettingService,
    public language: LanguageService,
    public dialogRef: MatDialogRef<ReviewSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.reviewGoogleTypeEnum = this.methodUtils.getStatuses(RevirGoogleTypeEnum);

    this.permissionUpdate = PermissionsEnum.UPDATE_BRANCH_SETTING;
    this.permissionStore = PermissionsEnum.STORE_BRANCH_SETTINGS;
    this.reviewForm = this.fb.group({
      review_link: [null, [Validators.required]],
      review_type: [null, [Validators.required]],
      stars_threshold: [null],
    });
  }

  get f() {
    return this.reviewForm.controls;
  }

  initReviewSettings() {
    this.f['review_link'].setValue(this.reviewSettings.review_link);
    this.f['stars_threshold'].setValue(this.reviewSettings.stars_threshold);
    this.f['review_type'].setValue(this.reviewSettings.review_type);
  }

  ngOnInit() {
    this.getReviewSettings();
  }

  submit() {
    this.submitted = true;
    if (this.reviewForm.invalid) {
      this.showNotification('Add Google Review Settings', 'Please check fields highlighted in red', 'info');
      return;
    }

    if (this.isEditreviewSettings) {
      this.updateReviewSetting();
      return;
    }
    this.createReviewSetting();
  }

  showNotification(title: string, message: string, type: any) {
    this.toastService.showTranslatedToast(type, title, message);
  }

  private getReviewSettings() {
    this.spinnerService.show().then();
    this.settingsService
      .getSettings()
      .pipe(first())
      .subscribe({
        next: value => {
          this.settings = value.data;
          if (!this.settings.google_review) {
            this.isAddreviewSettings = true;
            this.spinnerService.hide().then();
            return;
          } else {
            this.isEditreviewSettings = true;
          }
          this.reviewSettings = this.settings.google_review;
          this.initReviewSettings();
          this.onReviewTypeChange();
          this.spinnerService.hide().then();
        },
        error: (error: HttpErrorModel) => {
          this.spinnerService.hide().then();
          this.httpError = error;
        },
      });
  }

  private createReviewSetting() {
    this.spinnerService.show().then();
    this.formData.set('review_link', this.f['review_link'].value);
    if(this.f['stars_threshold'].value) {
      this.formData.set('stars_threshold', this.f['stars_threshold'].value);
    }
    this.formData.set('review_type', this.f['review_type'].value);
    const data = {
      google_review: Object.fromEntries(this.formData),
    }
    this.settingsService
      .addSettings(data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinnerService.hide().then();
          this.closeDialog();
          this.showNotification('Google Review Settings', 'Google Review Settings created successfully', 'success');
        },
        error: error => {
          this.spinnerService.hide().then();
          this.httpError = error;
          this.showNotification(error.message, error.errors.toString(), 'error');
        },
      });
  }

    private updateReviewSetting() {
      const reviewSettings = this.getChanges();
      const data = {
        google_review: Object.fromEntries(reviewSettings),
      }
      if (!reviewSettings.entries().next().value) {
        this.showNotification('Update Google Review Setting', 'No changes has been made', 'info');
        return;
      }
      this.spinnerService.show().then();
      this.settingsService
        .editSettings(data)
        .pipe(first())
        .subscribe({
          next: () => {
            this.spinnerService.hide().then();
            this.closeDialog();
            this.showNotification('Review Setting', 'Review Setting updated successfully', 'success');
          },
          error: error => {
            this.spinnerService.hide().then();
            this.httpError = error;
            this.showNotification(error.message, error.errors.toString(), 'error');
          },
        });
    }

  private getChanges() {
    if (this.f['review_link'].value !== this.reviewSettings?.review_link) {
      this.formData.set('review_link', this.f['review_link'].value);
    }
    if (this.f['stars_threshold'].value !== this.reviewSettings?.stars_threshold) {
      this.formData.set('stars_threshold', this.f['stars_threshold'].value);
    }
    if (this.f['review_type'].value !== this.reviewSettings?.review_type) {
      this.formData.set('review_type', this.f['review_type'].value);
    }
    return this.formData;
  }

   // Method to handle changes in the "Review Google Type" select field
   onReviewTypeChange() {
    const reviewTypeValue = +this.f['review_type'].value;
    if (reviewTypeValue === RevirGoogleTypeEnum.BASED_ON_THE_STARS_NUMBER as number) {
      this.showStarsThreshold = true;
      this.f['stars_threshold'].setValidators([Validators.required]);
    } else {
      this.showStarsThreshold = false;
      this.f['stars_threshold'].clearValidators();
    }
  
    this.f['stars_threshold'].updateValueAndValidity();
  }
  

  closeDialog() {
    this.dialogRef.close();
  }
}
