<div class="sort-container h-100 w-100">
  <label for="sort-select" class="sort-label tw-text-base tw-font-medium">
    <i class="bi bi-search"></i>
  </label>
  <input
    class="form-control sort-select h-100"
    id="sort-select"
    [(ngModel)]="filterKeyword"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onSearchChange($event)"
    placeholder="{{ placeholder }}"
    type="search" />
</div>
