import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TableService } from '@shared/services/table/table.service';
import { ToastService } from '@shared/services/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-generate-qr-dialog',
  templateUrl: 'generate-qr-dialog.component.html',
  styleUrls: ['generate-qr-dialog.component.scss'],
})
export class GenerateQrDialogComponent {
  @Input() tableID!: string;
  @Input() qrImage: SafeHtml;
  imageUrl: any | null = null;
  formData = new FormData();
  submitted = false;
  addForm: UntypedFormGroup;
  generateQr: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private toastService: ToastService,
    private tableService: TableService
  ) {
    this.addForm = this.fb.group({
      dots_color: [null],
      dots_shape: [null],
      border_shape: [null],
      bg_color: [null],
      border_color: [null],
      isGradient: [false],
      second_dots_color: [null],
      isRadialGradient: [false],
      link: [null],
      logo: [null],
    });
  }

  changeSource(event: any) {
    event.target.src = './assets/images/image.png';
  }

  get f() {
    return this.addForm.controls;
  }

  generateQR() {
    this.submitted = true;

    this.spinnerService.show().then();
    if (this.f['dots_color'].value) {
      this.formData.set('dots_color', this.f['dots_color'].value);
    }
    if (this.f['dots_shape'].value) {
      this.formData.set('dots_shape', this.f['dots_shape'].value);
    }
    if (this.f['border_shape'].value) {
      this.formData.set('border_shape', this.f['border_shape'].value);
    }
    if (this.f['border_shape'].value) {
      this.formData.set('border_shape', this.f['border_shape'].value);
    }
    if (this.f['bg_color'].value) {
      this.formData.set('bg_color', this.f['bg_color'].value);
    }
    if (this.f['border_color'].value) {
      this.formData.set('border_color', this.f['border_color'].value);
    }
    if (this.f['isGradient'].value) {
      this.formData.set('isGradient', this.f['isGradient'].value ? '1' : '0');
    if (this.f['isGradient'].value) {
      this.formData.set('second_dots_color', this.f['second_dots_color'].value);
    }}
    if (this.f['isGradient'].value) {
      this.formData.set('isRadialGradient', this.f['isRadialGradient'].value ? '1' : '0');
    }
    if (this.f['link'].value)
    this.formData.set('link', this.f['link'].value);
    if (this.f['logo'].value) {
      this.formData.set('logo', this.f['logo'].value);
    }

    this.tableService
      .generateQR(this.formData, this.tableID)
      .pipe(first())
      .subscribe({
        next: data => {
          this.spinnerService.hide().then();
          this.qrImage = this.sanitizer.bypassSecurityTrustHtml(data.data.qr_code);
          this.generateQr = true;
          this.showNotification('Generate QR', 'Generate QR successfully', 'success');
        },
        error: error => {
          this.spinnerService.hide().then();
          const errorMessage = error.message || 'An error occurred';
          const errorDetails = error.errors ? error.errors.toString() : '';
          this.showNotification(errorMessage, errorDetails, 'error');
        },
      });
  }

  saveQRImage() {
    this.spinnerService.show().then();
    const svgString = this.qrImage as string;
    const formData = new FormData();
    formData.append('svgFile', new Blob([svgString], { type: 'image/svg+xml' }), 'qr_code.svg');

    this.tableService
      .editTable(this.tableID, formData)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinnerService.hide().then();
          this.showNotification('QR', 'QR Save successfully', 'success');
          this.tableService.sendUpdate('updated');
        },
        error: error => {
          this.spinnerService.hide().then();
          const errorMessage = error.message || 'An error occurred';
          const errorDetails = error.errors ? error.errors.toString() : '';
          this.showNotification(errorMessage, errorDetails, 'error');
        },
      });
  }

  uploadImage(imageInput: any, type: string) {
    if (window.FileReader) {
      if (!imageInput.files[0]) {
        return;
      }
      const file: File = imageInput.files[0];
      this.formData.set(type, file); // Set the file in formData
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageUrl = reader.result; // Set the image URL to display it
      });
      reader.readAsDataURL(file);
    }
  }

  showNotification(title: string, message: string, type: any) {
    this.toastService.showToast(type, title, message);
  }
}
