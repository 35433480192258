<div class="container-fluid d-flex w-100 h-100 p-0 justify-content-center">
  <div class="p-0 w-100 h-100">
    <div class=" border-0 w-100 h-100 orderbtn grayboxshadow tw-flex tw-justify-center">
      <div class="tw-flex tw-items-center tw-justify-between tw-w-full tw-px-2">
        <div class="tw-flex tw-items-center tw-gap-2">
          <div class="quantity-container">{{ cartService.cartQuantity }}</div>
          <p class="my-auto tw-text-base text-white tw-font-semibold">
               Total: {{ cartService.totalPrice | dividePrice }} AED
          </p>
        </div>

          <div class="p-0">
            <button *ngIf="!isItemsToOrder" (click)="addOrder()" [disabled]="cartService.cartQuantity < 1" class="btn border-0 p-0 tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
              <p class="text-white my-auto tw-text-base tw-font-semibold">
                Add order
              </p>
                <i *ngIf="!isLoading" class="bi bi-arrow-right fw-bold text-white"></i>
                <div *ngIf="isLoading" class="spinner-border lodingcolor" style="width: 1.5rem; height: 1.5rem;" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
            </button>
            <button *ngIf="isItemsToOrder" (click)="addOrder()" [disabled]="cartService.cartQuantity < 1" class="btn border-0 p-0 tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
              <p class="text-white my-auto tw-text-base tw-font-semibold">
                Add to order
              </p>
                <i *ngIf="!isLoading" class="bi bi-arrow-right fw-bold text-white"></i>
                <div *ngIf="isLoading" class="spinner-border lodingcolor" style="width: 1.5rem; height: 1.5rem;" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
            </button>
          </div>

      </div>
    </div>
  </div>
</div>
