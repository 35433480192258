import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiplyPrice',
  standalone: false,
})
export class PriceFormatterMultiplyPipe implements PipeTransform {
  constructor() {}
  transform(value: number): number {
    const result = value * 100;
    return parseFloat(result.toFixed(2));
  }
}
