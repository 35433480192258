import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ToastService } from './toast.service';
import { Subject } from 'rxjs';  // Import RxJS Subject

@Injectable({
  providedIn: 'root',
})
export class FCMService {
  message: any = null;
  private fcmToken: string = '';
  private notificationsEnabled = true;
  // Create a Subject to emit new notification events
  private notificationSubject = new Subject<any>();

  constructor(private toastService: ToastService) {
    this.listenForMessages(); // Start listening for notifications when service is initialized
  }

  // Expose the notification subject as an observable so that other components can subscribe
  getNotificationObservable() {
    return this.notificationSubject.asObservable();
  }

  requestPermission(): void {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.fcmToken = currentToken;
        } else {
          console.log('No registration token available.');
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token:', err);
      });
  }

  getFcmToken(): string {
    return this.fcmToken;
  }

  // Method to listen for foreground messages
  listenForMessages(): void {
    const messaging = getMessaging();
    console.log('getMessaging')
    onMessage(messaging, (payload) => {
      console.log('New notification')
      const notificationTitle = payload.data ? payload.data['title'] : 'No Title';
      if (this.notificationsEnabled) {
        this.showNotification('New notification', notificationTitle, 'info');
      }
      // Emit the new notification event
      this.notificationSubject.next(payload);
      this.message = payload;
    });
  }

  showNotification(title: string, message: string, type: any) {
     // Play notification sound
    this.playNotificationSound();
    // Display toast notification
    this.toastService.showToast(type, title, message, 5000);
  }

  toggleNotifications(status: boolean): void {
    this.notificationsEnabled = status;
  }

  playNotificationSound() {
    const audio = new Audio('assets/sounds/sound.wav');
    audio.play().catch((error) => {
      console.error('Error playing notification sound:', error);
    });
  }
}
