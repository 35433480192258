import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {ResponseModel} from "@models/response.model";
import { CompanyService } from '../company.service';
import { AssociatedCompaniesModel } from '@shared/models/associated_companies.model';
import { RestaurantModel } from '@shared/models/restaurant.model';

@Injectable({providedIn: 'root'})
export class RestaurantService {
  private update = new Subject<any>();
  company: AssociatedCompaniesModel =JSON.parse(localStorage.getItem('company'));
  company_id : string =this.company?.id;
  restaurants: RestaurantModel[]=this.company?.restaurants;
  constructor(private http: HttpClient, private companyService:CompanyService) {
  }

  getAll(page = 0,
         perPage = 5,
         sort: string | null = null,
         filterKeyword: string | null = null,
         role: any | null = null
  ) {
    let params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }


    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    return this.http.get<ResponseModel<any>>('companies/'+ this.company_id +'/restaurants', {params});
  }

  addRestaurant(restaurant: any) {
    return this.http.post<ResponseModel<any>>('companies/'+ this.company_id +'/restaurants', restaurant);
  }

  getRestaurantByID(id: any) {
    return this.http.get<ResponseModel<any>>(`companies/${this.company_id}/restaurants/${id}`);
  }

  editRestaurant(restaurantId: any, restaurant: any) {
    return this.http.post<ResponseModel<any>>(`companies/${this.company_id}/restaurants/${restaurantId}?_method=PUT`, restaurant);
  }

  deleteRestaurant(id: string) {
    return this.http.delete(`companies/${this.company_id}/restaurants/${id}`);
  }


  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}
