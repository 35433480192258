export enum StorageKeysEnum {
  USER = 'user',
  COMPANY = 'company',
  RESTAURANT = 'restaurant',
  BRANCH = 'branch',
  STAFF = 'staff',
  UIUD = 'uiud',
  CURRENT_COUNTRY = 'current_country',
  CURRENT_LANGUAGE = 'current_language',
  LANGUAGES = 'languages',
  FCM_TOKEN = 'fcm_token',
}
