import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  get isMobile() {
    return window.innerWidth <= 640
  }

  constructor() { }


}
