<div class="dropdown" dir="ltr">
  <div class="header-actions">
    <div ngbDropdown #dropdown="ngbDropdown">
      <button class="p-0" id="profileDropdown" (click)="loadnotifications()" ngbDropdownToggle>
        <div class="position-relative">
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="--bs-badge-font-size: 0.7em">
           {{ notification_count }}
          </span>
          <!-- Bell Icon -->
          <nb-icon icon="bell-outline" class="tw-w-[25px] tw-text-primary"></nb-icon>
        </div>
      </button>
      <div aria-labelledby="profileDropdown" class="px-0 dropdown-menu" ngbDropdownMenu>
        <div class="row container bg-light p-0 m-0 mb-1 w-100">
          <div class="col">
            <h6 class="py-3">Notifications</h6>
          </div>
          <div class="col-7">
            <button
              *ngIf="!loading && notread"
              class="btn border-0 p-0 w-100 h-100 my-auto"
              (click)="mark_all_as_read()">
              <p class="py-3 fw-bold px-0 w-100 text-end my-auto" style="color: rgb(131, 123, 136)">Mark all as read</p>
            </button>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <span
            *ngIf="loading"
            class="spinner-grow spinner-grow-sm mx-3 my-auto align-middle"
            style="width: 3rem; height: 3rem;"
            role="status"
            aria-hidden="true"></span>
        </div>

        <!-- Notification List -->
        <div *ngIf="!loading && notifications.length > 0" class="text-start">
          <div *ngFor="let notification of notifications">
            <button
              class="btn border-0 p-0 m-0 w-100"
              [ngClass]="{ 'bg-light': notification.read === false }"
              (click)="handleNotificationClick(notification)">
              <div class="row w-100 container-fluid w-100">
                <div class="col-3 pt-2 d-flex justify-content-center">
                  <img src="{{ notification.data.image }}" class="my-auto" alt="" style="width: 40px; height: 40px" />
                </div>
                <div class="col-9 p-0 pb-2">
                  <p class="fw-bold mb-1 text-start">{{ notification.data.title }}</p>
                  <p class="mb-1 text-start notification-text">{{ notification.data.body }}</p>
                  <p class="graytext text-start">{{ formatLocalDate(notification.created_at).fromNow() }}</p>
                </div>
                <mat-divider class="w-100"></mat-divider>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="notifications.length < 1" class="px-3">There are no new notifications</div>
      </div>
    </div>
  </div>
</div>
