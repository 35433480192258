<nb-card class="mb-0 p-4 border-0 text-center">
  <nb-card-body class="px-4 d-flex flex-column">
    <h6 class="fw-bold">Additional notes</h6>
    <textarea
      type="text"
      class="form-control noteinput my-4"
      id="exampleFormControlInput1"
      placeholder="Write your notes here..."
      (keyup)="updateNotes($event)"></textarea>
  </nb-card-body>
  <nb-card-footer class="border-0 d-flex justify-content-center align-items-center gap-2">
    <button (click)="onClick(false)" class="black py-2">Cancel</button>
    <button (click)="addItem()" class="primary py-2">Add</button>
  </nb-card-footer>
</nb-card>
