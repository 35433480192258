import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorModel } from '@models/http-error.model';
import { AuthService } from '@services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  httpErrorModel: any;

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request);
      })
    );
  }

  handleResponseError(error: any, request: HttpRequest<any>) {
    if (request.url.includes('/auth/logout')) {
      return throwError(error);
    }

    switch (error.status) {
      case 422:
        this.httpErrorModel = this.getErrorMessage422(error);
        return throwError(this.httpErrorModel);
      case 401:
        this.authService.logout();
        return throwError(error);
      case 409: // Show message
        this.httpErrorModel = this.getErrorMessage409(error);
        return throwError(this.httpErrorModel);
      case 400: // Show message
        this.httpErrorModel = this.getErrorMessage400(error);
        return throwError(this.httpErrorModel);
      case 429:
        break;
      case 500:
        this.showSnackbar('Something went wrong, our team is working on fixing it');
        break;
      case 503:
        this.showSnackbar('Something went wrong, our team is working on fixing it');
        break;

      case 403: // Show message
        this.httpErrorModel = this.getErrorMessage403(error);
        this.showSnackbar(this.httpErrorModel.message);
        return throwError(this.httpErrorModel);
    }
    const errorMessage = error.message || error.status_code;

    return throwError(errorMessage);
  }

  getErrorMessage422(errorMessages: any) {
    const errorObj = errorMessages.error;

    const errorData = errorObj.data;

    const errorMessage = errorObj.message ?? 'Oh Snap!';
    const errorList: Array<string> = [];
    const errorStatusCode = errorObj.status_code;

    for (const item in errorData) {
      if (item) {
        errorList.push(errorData[item]);
      }
    }

    return new HttpErrorModel(errorMessage, errorList, errorStatusCode);
  }

  getErrorMessage403(errorMessages: any) {
    const errorObj = errorMessages.error;

    const errorData = errorObj?.data;

    const errorMessage = errorObj.message ?? 'Oh Snap!';
    const errorList: Array<string> = [];
    const errorStatusCode = errorObj.status_code;

    for (const item in errorData) {
      if (item) {
        errorList.push(errorData[item]);
      }
    }

    return new HttpErrorModel(errorMessage, errorList, errorStatusCode);
  }

  getErrorMessage409(errorMessages: any) {
    const errorObj = errorMessages.error;

    const errorData = errorObj?.data;

    const errorMessage = errorObj.message ?? 'Oh Snap!';
    const errorList: Array<string> = [];
    const errorStatusCode = errorObj.status_code;

    for (const item in errorData) {
      if (item) {
        errorList.push(errorData[item]);
      }
    }

    return new HttpErrorModel(errorMessage, errorList, errorStatusCode);
  }
  getErrorMessage400(errorMessages: any) {
    const errorObj = errorMessages.error;

    const errorData = errorObj?.data;

    const errorMessage = errorObj.message ?? 'Oh Snap!';
    const errorList: Array<string> = [];
    const errorStatusCode = errorObj.status_code;

    for (const item in errorData) {
      if (item) {
        errorList.push(errorData[item]);
      }
    }

    return new HttpErrorModel(errorMessage, errorList, errorStatusCode);
  }
  showSnackbar(message: string): void {
    this.snackBar.open(message, 'close', {
      duration: 20000,
      panelClass: ['snackbar'],
    });
  }
}
