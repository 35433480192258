import { Injectable } from '@angular/core';
import { ItemModel } from '@shared/models/appointment.model';
import { MenuItemModel } from '@shared/models/menu-item.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartProductsSubject = new BehaviorSubject<any[]>([]);
  cartProducts$ = this.cartProductsSubject.asObservable();
  totalPrice: number = 0;
  cartQuantity: number = 0;

  constructor() {
    this.getCartProduct();
  }

  getCartProduct() {
    this.fetchCartData();
    this.updateCartQuantity();
    this.updateTotalPrice();
  }

  private fetchCartData() {
    const cartData = localStorage.getItem('cart');
    if (cartData) {
      this.cartProductsSubject.next(JSON.parse(cartData));
    } else {
      this.cartProductsSubject.next([]);
    }
  }

  updateTotalPrice() {
    const currentCart = this.cartProductsSubject.getValue();
    this.totalPrice = currentCart.reduce((acc, item) => acc + item.price, 0);
  }

  updateCartQuantity() {
    const currentCart = this.cartProductsSubject.getValue();
    this.cartQuantity = currentCart.length;
  }

  addToCart(event: MenuItemModel, notes: string) {
    const currentCart = this.cartProductsSubject.getValue();
    const item = {
      id: event.id,
      price: event.price,
    };
    if(notes != null) {
      item['notes']=notes;
    }
    currentCart.push(item);
    this.cartProductsSubject.next(currentCart);
    localStorage.setItem('cart', JSON.stringify(currentCart));
    this.updateCartQuantity();
    this.updateTotalPrice();
  }

  removeFromCart(id: string) {
    const currentCart = this.cartProductsSubject.getValue();
    const index = currentCart.map(item => item.id).lastIndexOf(id);
    if (index > -1) {
      currentCart.splice(index, 1); // remove the last occurrence of the item with the specified id
      this.cartProductsSubject.next(currentCart);
      localStorage.setItem('cart', JSON.stringify(currentCart));
      this.updateCartQuantity();
      this.updateTotalPrice();
    }
  }

  countItem(id) {
    const currentCart = this.cartProductsSubject.getValue();
    if (currentCart.length < 1) {
      return 0;
    } else {
      return currentCart.reduce((count, item) => (item.id === id ? count + 1 : count), 0);
    }
  }
}
