import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {ResponseModel} from "@shared/models/response.model";
import {RestaurantModel} from '@shared/models/restaurant.model';
import {TableModel} from '@shared/models/table.model';
import { BranchModel } from '@shared/models/branch.model';



@Injectable({providedIn: 'root'})
export class TableService {
  private update = new Subject<any>();
  restaurant: RestaurantModel= JSON.parse(localStorage.getItem('restaurant'))
  restaurantID:string = this.restaurant.id;
  branch: BranchModel= JSON.parse(localStorage.getItem('branch'))
  branchID:string = this.branch.id;
  constructor(private http: HttpClient) {
  }

  getAll(page = 0,
         perPage = 5,
         sort: string | null = null,
         filterKeyword: string | null = null,
         role: any | null = null
  ) {
    let params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }


    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    return this.http.get<ResponseModel<TableModel[]>>('restaurants/'+ this.restaurantID +'/branches/'+ this.branchID +'/restaurant-tables', {params});
  }

  addTable(member: any) {
    return this.http.post<ResponseModel<TableModel>>('restaurants/'+ this.restaurantID +'/branches/'+ this.branchID +'/restaurant-tables', member);
  }

  getTableByID(id: any) {
    return this.http.get<ResponseModel<TableModel>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/restaurant-tables/${id}`);
  }

  editTable(id: any, member: any) {
    return this.http.post<ResponseModel<TableModel>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/restaurant-tables/${id}?_method=PUT`, member);
  }

  deleteTable(id: string) {
    return this.http.delete(`restaurants/${this.restaurantID}/branches/${this.branchID}/restaurant-tables/${id}`);
  }

  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }

  generateQR(qr: any, id:string) {
    return this.http.post<ResponseModel<any>>('restaurants/'+ this.restaurantID +'/branches/'+ this.branchID +'/restaurant-tables/'+ id +'/qr', qr);
  }
}
