import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-icon-container',
  templateUrl: './image-icon-container.component.html',
  styleUrls: ['./image-icon-container.component.css'],
})
export class ImageIconContainerComponent {
  @Input() image: string = '';
}
