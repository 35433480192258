<div
  class="p-0 m-3 mx-auto tw-w-40 tw-h-40 tw-rounded-xl"
  [ngClass]="{
    'bg-white': status === 'AVAILABLE',
    'secondary-background-color': status === 'OCCUPIED',
    'strong-orange-background-color': status === 'LOCK',
    'mauve-background-color': status === 'RESERVED',
  }">
  <div
    class="d-flex justify-content-center justify-align-center tw-h-8 tw-w-8 tw-rounded-tl-xl tw-rounded-br-lg"
    [ngClass]="[status === 'AVAILABLE' ? 'main-background-color' : 'glass-background']">
    <p class="p-0 m-auto tw-text-base tw-font-medium text-white">{{ tableNumber }}</p>
  </div>
  <div class="w-100 h-75 p-2 d-flex flex-column m-auto">
    <img *ngIf="status !== 'AVAILABLE'" class="tw-size-10 m-auto" src="assets/images/tableimage.png" alt="" />
    <img *ngIf="status === 'AVAILABLE'" class="tw-size-10 m-auto" src="assets/images/tableactive.png" alt="" />
    <div class="mt-2">
      <div
        class="rounded-5 text-center py-1 mt-3"
        [ngClass]="[status === 'AVAILABLE' ? 'main-background-color' : 'glass-background']">
        <p class="text-white tw-text-xs">{{ status | formatType}}</p>
      </div>
    </div>
  </div>
</div>
