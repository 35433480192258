import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from '@theme/shared/shared.module';
import {AdminComponent} from '@theme/layout/admin/admin.component';
import {ConfigurationComponent} from '@theme/layout/admin/configuration/configuration.component';
import {NavBarComponent} from '@theme/layout/admin/nav-bar/nav-bar.component';
import {NavigationComponent} from '@theme/layout/admin/navigation/navigation.component';
import {NavLeftComponent} from '@theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavRightComponent} from '@theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {NavContentComponent} from '@theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavLogoComponent} from '@theme/layout/admin/navigation/nav-logo/nav-logo.component';
import {NavGroupComponent} from '@theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavItemComponent} from '@theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavSearchComponent} from '@theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavigationItem} from '@theme/layout/admin/navigation/navigation';
import {ToggleFullScreenDirective} from '@theme/shared/components/full-screen/toggle-full-screen';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TokenInterceptor} from "./core/interceptors/token.interceptor";
import {ErrorInterceptor} from "./core/interceptors/error.interceptor";
import {NgxPermissionsModule} from "ngx-permissions";
import {
  NbCardModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
  NbWindowModule
} from '@nebular/theme';
import {NbEvaIconsModule} from "@nebular/eva-icons";
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask'
import {NgxSpinnerModule} from "ngx-spinner";
import {NgSelectModule} from "@ng-select/ng-select";
import {ThemeModule} from "@theme/theme.module";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MethodsUtils} from "@shared/utils/methods.utils";
import {GuestComponent} from "@theme/layout/guest/guest.component";
import {ChangeStatusDialogComponent} from './demo/change-status-dialog/change-status-dialog.component';
import {CoreModule} from "@shared/core.module";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgxImageCompressService} from "ngx-image-compress";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {MatSelectModule} from "@angular/material/select";
import { ServiceWorkerModule } from "@angular/service-worker";
import { initializeApp } from "firebase/app";
import {environment} from "@env";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatSidenavModule} from '@angular/material/sidenav';
import { MobileMenuItemComponent } from '@theme/layout/admin/mobile-menu/mobile-menu-item/mobile-menu-item.component';

initializeApp(environment.firebase);



@NgModule({

  declarations: [
    AppComponent,
    AdminComponent,
    GuestComponent,
    ConfigurationComponent,
    NavBarComponent,
    NavigationComponent,
    NavLeftComponent,
    NavRightComponent,
    NavContentComponent,
    NavLogoComponent,
    NavGroupComponent,
    NavItemComponent,
    NavSearchComponent,
    ToggleFullScreenDirective,
    ChangeStatusDialogComponent,
    MobileMenuItemComponent,
  ],
  exports: [MatDatepickerModule],
    imports: [
        MatDatepickerModule,
        AngularEditorModule,
        BrowserModule,
        AppRoutingModule,
        MatButtonModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatMomentDateModule,
        NgSelectModule,
        ThemeModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NgxSpinnerModule,
        NbCardModule,
        NbThemeModule.forRoot({name: 'default'}),
        HttpClientModule,
        NbInputModule,
        MatDividerModule,
        NbWindowModule.forRoot({
            buttons: {
                minimize: false,
                maximize: false,
                fullScreen: false,
                close: true,
            },
            hasBackdrop: false,
            closeOnBackdropClick: false,
            windowClass: 'custom-window',
        }),
        NbLayoutModule,
        NbToastrModule.forRoot(),
        NbEvaIconsModule,
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        NbLayoutModule,
        NgxPermissionsModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        BrowserAnimationsModule,
        CoreModule,
        NbFormFieldModule,
        NbIconModule,
        MatInputModule,
        NgxMaskDirective,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
      MatSnackBarModule,
      MatSidenavModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
    ],

  providers: [
    MethodsUtils,
    NgxImageCompressService,
    provideNgxMask(),
    NavigationItem,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

