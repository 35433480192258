import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  permission?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/dashboard',
    icon: 'assets/new-icons/dashboard-report.png',
    classes: 'nav-item',
  },
  {
    id: 'panel',
    title: 'Panel',
    type: 'item',
    url: '/panel',
    icon: 'assets/new-icons/dashboards.svg',
    classes: 'nav-item',
  },
  {
    id: 'profile',
    title: 'Profile',
    type: 'item',
    url: '/profile',
    icon: 'assets/new-icons/person.png',
    classes: 'nav-item',
  },
  {
    id: 'restaurant',
    title: 'Restaurants',
    type: 'item',
    permission: 'INDEX_RESTAURANT',
    url: '/restaurant',
    icon: 'assets/new-icons/restaurant2.svg',
    classes: 'nav-item',
  },
  {
    id: 'staff',
    title: 'Staff',
    type: 'item',
    permission: 'INDEX_STAFF',
    url: '/staff',
    icon: 'assets/new-icons/staff.svg',
    classes: 'nav-item',
  },
  {
    id: 'Orders',
    title: 'Orders',
    type: 'item',
    permission: 'INDEX_ORDER',
    url: '/orders',
    icon: 'assets/new-icons/order.svg',
    classes: 'nav-item',
  },
  {
    id: 'menu',
    title: 'Menus',
    type: 'item',
    permission: 'INDEX_MENU',
    url: '/menus',
    icon: 'assets/new-icons/menu.svg',
    classes: 'nav-item',
  },
  {
    id: 'table',
    title: 'Tables',
    type: 'item',
    permission: 'INDEX_RESTAURANT_TABLE',
    url: '/tables',
    icon: 'assets/new-icons/table.svg',
    classes: 'nav-item',
  },
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
