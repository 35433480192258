import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {ResponseModel} from "@shared/models/response.model";
import {RestaurantModel} from '@shared/models/restaurant.model';
import { MenuCategoryModel } from '@shared/models/menu-category.model';
import { BranchModel } from '@shared/models/branch.model';

@Injectable({providedIn: 'root'})
export class NewMenuCategoriesService {
  private update = new Subject<any>();
  restaurant: RestaurantModel= JSON.parse(localStorage.getItem('restaurant'))
  restaurantID:string = this.restaurant.id;
  branch: BranchModel= JSON.parse(localStorage.getItem('branch'))
  branchID:string = this.branch.id;

  constructor(private http: HttpClient) {}

  getAll(menuID : string,
         page = 0,
         perPage = 5,
         sort: string | null = null,
         filterKeyword: string | null = null,
         role: any | null = null,
  ) {
    let params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }

    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    return this.http.get<ResponseModel<MenuCategoryModel[]>>('restaurants/'+ this.restaurantID +'/branches/'+ this.branchID +'/menus/'+ menuID +'/menu-categories' , {params});
  }

  addMenuCategory(menuID : string, menuCategory: any) {
    return this.http.post<ResponseModel<MenuCategoryModel>>('restaurants/'+ this.restaurantID +'/branches/'+ this.branchID +'/menus/'+ menuID +'/menu-categories', menuCategory);
  }

  getMenuCategoryByID(menuID : string, id: any) {
    return this.http.get<ResponseModel<MenuCategoryModel>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/menus/${menuID}/menu-categories/${id}`);
  }

  editMenuCategory(menuID : string, id: any, menuCategory: any) {
    return this.http.post<ResponseModel<MenuCategoryModel>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/menus/${menuID}/menu-categories/${id}?_method=PUT`, menuCategory);
  }

  deleteMenuCategory(menuID : string, id: string) {
    return this.http.delete(`restaurants/${this.restaurantID}/branches/${this.branchID}/menus/${menuID}/menu-categories/${id}`);
  }

  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}
