<nb-card class="mb-0 p-4 border-0 text-center scrollable-content">
  <nb-card-body class="px-4 d-flex flex-column qr-dialog-container">
    <form [formGroup]="addForm" class="reset">
      <div class="row">

        <!-- First Column -->
        <div class="col-12 col-md-4">
          <div class="create-user-row">
            <!-- Dots Color -->
            <div class="text-start mb-3">
              <label class="form-label" for="dots_color">Dots Color:</label>
              <input formControlName="dots_color" id="dots_color" type="color" class="form-control">
            </div>

            <!-- Background Color -->
            <div class="text-start mb-3">
              <label class="form-label" for="bg_color">Background Color:</label>
              <input formControlName="bg_color" id="bg_color" type="color" class="form-control">
            </div>

            <!-- Border Color -->
            <div class="text-start mb-3">
              <label for="border_color">Border Color:</label>
              <input formControlName="border_color" id="border_color" type="color" class="form-control">
            </div>

            <!-- Gradient -->
            <div class="row text-start mb-3 d-flex justify-content-center">
              <label class="form-label" for="isGradient">Gradient:</label>
              <input formControlName="isGradient" id="isGradient" type="checkbox" class="form-check-input" style="width: 50%; height: 25px;">
            </div>

            <!-- Second Dots Color (If Gradient is selected) -->
            <div *ngIf="addForm.controls['isGradient'].value" class="text-start mb-3">
              <div class="text-start mb-3">
                <label for="second_dots_color">Second Dots Color:</label>
                <input formControlName="second_dots_color" id="second_dots_color" type="color" class="form-control">
              </div>
            </div>

            <!-- Radial Gradient -->
            <div class="row text-start mb-3 d-flex justify-content-center">
              <label class="form-label" for="isRadialGradient">Radial Gradient:</label>
              <input formControlName="isRadialGradient" id="isRadialGradient" type="checkbox" class="form-check-input" style="width: 50%; height: 25px;">
            </div>

            <!-- Logo -->
            <div class="text-start mb-3">
              <label class="form-label">Logo</label>
              <div class="image-container mx-auto">
                <img (error)="changeSource($event)" [src]="imageUrl" alt="Image" class="user-images" />
                <div class="camera-icon" (click)="fileInput.click()">
                  <img src="assets/images/Camera.png" />
                  <input #fileInput type="file" class="d-none" (change)="uploadImage(fileInput, 'logo')" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Second Column -->
        <div class="col-12 col-md-4">
          <div class="create-user-row">
            <!-- Dots Shape -->
            <div class="text-start mb-3">
              <label class="form-label">Dots Shape:</label>
              <div class="radio-group">
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="circular">
                  <img src="./assets/qr-icons/circular.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="square">
                  <img src="./assets/qr-icons/square.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="dot">
                  <img src="./assets/qr-icons/dot.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="japanese">
                  <img src="./assets/qr-icons/japnese.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="pointed-edge-cut">
                  <img src="./assets/qr-icons/pointed-edge-cut.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="pointed-smooth">
                  <img src="./assets/qr-icons/pointed-smooth.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="star">
                  <img src="./assets/qr-icons/star.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="rounded-in-smooth">
                  <img src="./assets/qr-icons/rounded-in-smooth.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="diamond">
                  <img src="./assets/qr-icons/diamond.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="mosaic">
                  <img src="./assets/qr-icons/mosaic.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="dots_shape" value="circle-zebra-vertical">
                  <img src="./assets/qr-icons/circle-zebra-vertical.jpg" alt="Image" class="user-image small-image" />
                </label>
              </div>
            </div>

            <!-- Border Shape -->
            <div class="text-start mb-3">
              <label class="form-label">Border Shape:</label>
              <div class="radio-group">
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="square">
                  <img src="./assets/qr-icons/border-shape/frame0.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="squircle">
                  <img src="./assets/qr-icons/border-shape/frame1.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="tilted-square">
                  <img src="./assets/qr-icons/border-shape/frame2.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="clipped-corner-square">
                  <img src="./assets/qr-icons/border-shape/frame3.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="square-with-dot">
                  <img src="./assets/qr-icons/border-shape/frame4.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="inner-rounded-rectangle">
                  <img src="./assets/qr-icons/border-shape/frame5.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="thick-bordered-circle">
                  <img src="./assets/qr-icons/border-shape/frame6.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="circular">
                  <img src="./assets/qr-icons/border-shape/frame7.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="rounded-square">
                  <img src="./assets/qr-icons/border-shape/frame8.jpg" alt="Image" class="user-image small-image" />
                </label>
                <label class="radio-label">
                  <input type="radio" formControlName="border_shape" value="rounded-rectangle">
                  <img src="./assets/qr-icons/border-shape/frame9.jpg" alt="Image" class="user-image small-image" />
                </label>
              </div>
            </div>

            <!-- Link -->
            <div class="text-start mb-3">
              <label class="form-label" for="link">Link:</label>
              <input formControlName="link" id="link" type="text" class="form-control">
            </div>

            <!-- Generate Button -->
            <div>
              <button (click)="generateQR()" type="button" class="btn btn-outline-success">
                <i class="feather icon-check-circle"></i> Generate
              </button>
            </div>
          </div>
        </div>

        <!-- Third Column -->
        <div class="col-12 col-md-4">
        <div class="text-start mb-3">
          <div class="d-flex align-items-center justify-content-center row">
            <div class="row mt-3">
              <div class="qr-preview form-group full-width">
                <div *ngIf="qrImage" class="qr-result d-flex align-items-center justify-content-center flex-column">
                  <ng-container *ngIf="generateQr">
                    <div [innerHTML]="qrImage" class="qr-image qr-result d-flex align-items-center justify-content-center flex-column" style="width: 130%; object-fit: cover"></div>
                  </ng-container>
                  <ng-container *ngIf="!generateQr">
                    <img  (error)="changeSource($event)" [src]="qrImage" alt="QR Code" class="qr-image" style="width: 120%; object-fit: cover">
                  </ng-container>
                </div>
              </div>
              <button (click)="saveQRImage()" type="button" class="btn btn-outline-success mt-3">
                <i class="feather icon-check-circle"></i> Save
              </button>
            </div>
          </div>
        </div>
      </div>

      </div>
    </form>
  </nb-card-body>
</nb-card>
