<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="tw-w-screen sm:tw-w-[300px]" mode="over">
    <!-- Navbar -->
    <div class="tw-flex tw-justify-between tw-bg-white tw-p-2 tw-items-center tw-h-[50px] tw-w-full tw-sticky tw-top-0">
      <nb-icon
        (click)="drawer.toggle()"
        icon="menu-outline"
        class="tw-w-[25px] tw-text-primary tw-cursor-pointer"></nb-icon>
      <span class="tw-text-primary tw-text-lg">Menu</span>
      <ul>
        <li>
          <div class="dropdown drp-user" ngbDropdown placement="auto">
            <a style="color: white" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
              <i class="icon feather icon-settings"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end profile-notification" ngbDropdownMenu>
              <ul class="pro-body">
                <li>
                  <a (click)="userProfile()" class="dropdown-item main-color"
                    ><i class="feather icon-user"></i> Profile</a
                  >
                </li>
                <li>
                  <a (click)="appVersion()" class="dropdown-item main-color"
                    ><i class="feather icon-share"></i> App version</a
                  >
                </li>
                <li>
                  <a (click)="logout()" class="dropdown-item main-color"
                    ><i class="feather icon-log-out"></i> Log out</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- Nav Links -->
    <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
      <div class="px-3" *ngIf="userType === 'admin'">
        <!-- Select Company -->
        <div *ngIf="companyService.companies && companyService.companies.length > 1">
          <p class="main-color">Select Company</p>
          <select class="form-select mb-1 main-color" [(ngModel)]="selectedCompany" (change)="setCompany($event)">
            <option *ngFor="let item of companyService.companies" [ngValue]="item">
              {{ item.name }}
            </option>
          </select>
        </div>
      
        <!-- Select Restaurant -->
        <div *ngIf="restaurantService.restaurants && restaurantService.restaurants.length > 1">
          <p class="main-color">Select Restaurant</p>
          <select class="form-select mb-1 main-color" [(ngModel)]="selectedRestaurant" (ngModelChange)="setRestaurant($event)">
            <option *ngFor="let item of restaurantService.restaurants" [ngValue]="item">
              {{ item.name }}
            </option>
          </select>
        </div>
      
        <!-- Select Branch -->
        <div *ngIf="branches && branches.length > 1">
          <p class="main-color">Select Branch</p>
          <select class="form-select mb-1 main-color" [(ngModel)]="selectedBranch" (ngModelChange)="setBranch($event)">
            <option *ngFor="let item of branches" [ngValue]="item">
              {{ item.name }} ({{ item.slug }})
            </option>
          </select>
        </div>
      </div>
      
      
      <app-mobile-menu-item
        (click)="drawer.toggle()"
        *ngFor="let item of mobileMenuItems"
        [label]="item.label"
        [icon]="item.icon"
        [route]="item.route"
        [clickCallback]="item.clickCallback">
      </app-mobile-menu-item>
    </div>
  </mat-drawer>
  <!-- Container -->
  <div class="tw-m-0">
    <!-- Navbar -->
    <div class="tw-flex tw-justify-between tw-p-2 tw-items-center tw-h-[50px] tw-w-screen tw-sticky tw-top-0">
      <nb-icon (click)="drawer.toggle()" icon="menu-outline" class="tw-w-[25px] tw-text-primary"></nb-icon>
      <span class="tw-text-primary tw-text-lg">{{ pageName }}</span>
      <app-notifications class="pe-3 pt-1"></app-notifications>
    </div>
    <!-- Content -->
    <div class="content tw-mb-[60px]">
      <router-outlet></router-outlet>
    </div>
    <!-- Footer -->
    <div
      class="tw-w-screen tw-bg-white tw-flex tw-justify-between tw-px-2 tw-py-2 tw-items-center tw-fixed tw-bottom-0 tw-h-[60px]">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/panel"
        routerLinkActive
        #rla="routerLinkActive">
        <img
          [src]="
            rla.isActive ? 'assets/new-icons/mobile-dashboard-selected.svg' : 'assets/new-icons/mobile-dashboard.svg'
          "
          alt="" />
        <span [ngClass]="rla.isActive ? 'tw-text-primary' : ''">Dashboard</span>
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/orders"
        routerLinkActive
        #rla1="routerLinkActive">
        <img
          [src]="rla1.isActive ? 'assets/new-icons/mobile-order-selected.svg' : 'assets/new-icons/mobile-order.svg'"
          alt="" />
        <span [ngClass]="rla1.isActive ? 'tw-text-primary' : ''">Orders</span>
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/tables"
        routerLinkActive
        #rla2="routerLinkActive">
        <img
          [src]="rla2.isActive ? 'assets/new-icons/mobile-tables-selected.svg' : 'assets/new-icons/mobile-tables.svg'"
          alt="" />
        <span [ngClass]="rla2.isActive ? 'tw-text-primary' : ''">Tables</span>
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/profile"
        routerLinkActive
        #rla3="routerLinkActive">
        <img
          [src]="rla3.isActive ? 'assets/new-icons/mobile-profile-selected.svg' : 'assets/new-icons/mobile-profile.svg'"
          alt="" />
        <span [ngClass]="rla3.isActive ? 'tw-text-primary' : ''">Profile</span>
      </div>
    </div>
  </div>
</mat-drawer-container>
