<div class="container-fluid p-0">
  <div class="tw-flex tw-gap-2 py-4 my-2 itemcard tw-px-2">
    <div class="col-12 p-0">
      <div class="row">
        <div class="col-8">
          <h4 class="text-black tw-text-base" [ngClass]="{'text-start' : !language.isArabic, 'text-end' : language.isArabic}">{{ item.title }}</h4>
        </div>
        <div class="col-4">
          <h5 class="main-color px-2 text-end tw-text-base" >
            <span class="fw-bold">{{ item.price | dividePrice }} </span> {{ item.currency?.value }}
          </h5>
        </div>
      </div>
      <div class="row" [ngClass]="{'text-start' : !language.isArabic, 'text-end' : language.isArabic}">
        <div class="col-6" >
          <p class="descriptioncard secondary-color">
            {{ item.description }}
          </p>
          <button (click)="specifyOrder(item)" class="btn p-0 m-0">
            <p class="main-color tw-text-sm">{{'Specify order >' | translate}}</p>
          </button>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button
            (click)="deleteFromCart(item.id)"
            *ngIf="cartService.countItem(item.id) > 0"
            class="btn p-0 m-0 tw-size-9 tw-rounded-lg tw-flex tw-justify-center tw-items-center bg-contaner tw-shadow tw-cursor-pointer tw-leading-none mx-2 hover:tw-bg-primary tw-transition-all secondary-color">
            <i class="bi bi-dash m-auto"></i>
          </button>

          <p *ngIf="cartService.countItem(item.id) > 0" class="my-2 tw-text-lg secondary-color">
            {{ cartService.countItem(item.id) }}
          </p>
          <button
            (click)="addToCart(item)"
            class="btn p-0 m-0 tw-size-9 tw-rounded-lg tw-flex tw-justify-center tw-items-center text-end bg-contaner tw-shadow tw-cursor-pointer tw-leading-none mx-2 hover:tw-bg-primary tw-transition-all secondary-color">
            <i class="bi bi-plus m-auto"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
