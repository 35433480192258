import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NotificationsService } from '@services/notifications.service';
import { NotificationsModel } from '@models/notifications.model';
import { Router } from '@angular/router';
import { NotificationTypeEnum } from '@shared/enum/notification-type.enum';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  message: any = null;
  notification_count: number = 0;
  isBrowser: boolean = false;
  notifications: NotificationsModel[] = [];
  loading: boolean = true;
  created_at: string = '';
  formattedDate: string = '';
  notread: boolean = false;
  type: string;
  constructor(
    private notificationsService: NotificationsService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.loadnotifications();
  }

  loadnotifications() {
    this.notificationsService.show_all_notifications().subscribe({
      next: res => {
        this.notifications = res.data;
        this.notification_count = 0;
        for (const notification of this.notifications) {
          if (notification.read == false) {
            this.notification_count += 1;
            this.notread = true;
          }
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  formatLocalDate(dateString: string) {
    const utcMoment = moment.utc(dateString);
    const localMoment = utcMoment.local();
    return localMoment;
  }

  mark_as_read(id: any) {
    this.loading = true;
    this.notificationsService.notification_as_read(id).subscribe({
      next: () => {
        this.loadnotifications();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  mark_all_as_read() {
    this.loading = true;
    this.notificationsService.mark_all_as_read().subscribe({
      next: () => {
        this.loadnotifications();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  handleNotificationClick(notification: NotificationsModel) {
    this.mark_as_read(notification.id);
    if (
      notification.data.type === NotificationTypeEnum.NEW_ORDER ||
      notification.data.type === NotificationTypeEnum.CANCEL_ORDER ||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER_TO_UPDATE_ORDER
    ) {
      this.router.navigate(['orders/', notification.data.order_id]);
      this.dropdown.close();
    } else if (
      notification.data.type === NotificationTypeEnum.UNLOCK_RESTAURANT_TABLE ||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER
    ) {
      this.router.navigate(['tables/', notification.data.table_id]);
      this.dropdown.close();
    }
  }
}
